<template>
  <div class="nav">
    <el-menu
      class="el-menu-demo"
      background-color="#ae703e"
      text-color="#fff"
      active-text-color="#fff"
      mode="horizontal"
    >
      <el-menu-item index="/" @click="changePage1()">首页</el-menu-item>
      <template v-for="(i, k) in navmenu">
        <el-submenu :key="k" :index="1 + '-' + k" v-if="i.menuChildrenList.length != 0">
          <template slot="title">{{ i.menuName }}</template>
          <el-menu-item
            v-for="(item, index) in i.menuChildrenList"
            :key="index"
            :index="1 + '-' + k + '-' + index"
            @click="
              changePage(
                '/list-text',
                item.id,
                item.menuParentId,
                i.menuName,
                item.name,
                item.menuType
              )
            "
            >{{ item.name }}</el-menu-item
          >
          
        </el-submenu>
        <el-menu-item
          v-else
          :key="k"
          :index="1 + '-' + k"
          @click="checkpage('/list-text', i.id, i.menuId, i.menuName, i.name)"
          >{{ i.menuName }}</el-menu-item
        >  
      </template>
        <el-submenu index="2" v-if=" this.navmenu.length>8">
    <template slot="title">更多</template>
    <el-submenu  :index="2 + '-' + k1"  v-for="(item,k1) in more" :key="k1">
      <template slot="title">{{item.menuName}}</template>
      <el-menu-item  v-for="(i,k2) in item.menuChildrenList" :key="k2" :index="2 + '-' + k2"     @click="
              changePage(
                '/list-text',
                i.id,
                i.menuParentId,
                item.menuName,
                i.name,
                i.menuType
              )
            ">{{i.name}}</el-menu-item>
      <!-- <el-menu-item index="2-4-2">选项2</el-menu-item> -->
    </el-submenu>
    </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import apinavmenu from "../api/api_navmenu";

export default {
  name: "Nav",
  data() {
    return {
      navmenu: [],
      more:[]
      
    };
  },
  props: {
    fromPath: String,
  },
  methods: {
    changePage(path, classify, menuId, titleName, title, menuType) {
      if (menuType == 0) {
        this.$router.push({
          path,
          query: { classify, menuId, titleName, title },
        });
      } else if (menuType == 1) {
        this.$router.push({
          path: "detail",
          query: {
            classify,
           title:titleName,
            title1:title
          },
        });
      }
    },
    checkpage(path, classify, menuId, titleName, title) {
      this.$router.push({
        path,
        query: { classify, menuId, titleName, title },
      });
    },
    changePage1() {
      this.$router.push({
        path: "/",
      });
    },
  },
  created() {
    apinavmenu.getMenu().then((res) => {
      if(res.data.length>8){
        this.navmenu = res.data.slice(0,7);
         this.more=res.data.slice(7,res.data.length)
      }else{
        this.navmenu = res.data;
       
      }
      
      
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav {
  width: 100%;
  height: auto;
  background-color: #ae703e;
  box-shadow: 0px 3px 6px 0px rgba(47, 69, 59, 0.66);
}
.nav .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
}
.el-menu.el-menu--horizontal {
  width: 1250px;
  background-color: #ae703e;
  margin: 0px auto;
  color: #333;
  height: 50px;
}
.el-carousel--horizontal {
  width: 100% !important;
}
.el-menu-item {
  text-align: center;
  font-size: 15px;
  width: 135px;
}
/* .el-menu--horizontal > .el-submenu .el-submenu__title {
  text-align: center;
  font-size: 15px;
  width: 95px;
} */
.el-menu--horizontal > .el-menu-item.is-active {
  border: none;
  border-bottom: 3px solid #fff;
  color: #fff;
  background-color: #ae703e;
  /* width: 95px; */
  height: 50px;
  line-height: 50px;
}
.el-menu--horizontal > .el-submenu .el-submenu__title {
  color: #fff;
}
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  color: #fff;
}
.el-menu--horizontal > .el-menu-item {
  height: 50px;
  line-height: 50px;
  color: #fff;
}
.el-menu.el-menu--horizontal {
  border: none;
}
.el-menu--horizontal > .el-submenu {
  width: 130px;
  height: 50px;
}
</style>
