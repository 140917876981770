<template>
  <div class="hedertotal">
    <div class="header">
      <div class="logo" @click="$router.push({ path: '/' })">
        <img src="../images/zzsrlogo.png" alt="" />
        <!-- <p>尉氏县公共数据文化平台</p> -->
      </div>
      <div class="login">
        <div style="marigin-top: 40px" class="serchdiv">
          <el-input
            placeholder="请输入关键字"
            v-model="input3"
            class="input-with-select"
            style="
              width: 600px;
              border-top-left-radius: 15px;
              border-bottom-left-radius: 15px;
              border-top-right-radius: 15px;
              border-bottom-right-radius: 15px;
              border: 1px solid #d9d8d8;
            "
            @keyup.enter.native="entersearch()"
          >
            <!-- <el-select
              style="
                width: 120px;
                background-color: #fff;
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
              "
              v-model="select"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="图书" value="1"></el-option>
              <el-option label="期刊" value="2"></el-option>
              <el-option label="绘本" value="3"></el-option>
            </el-select> -->
            <el-button
              style="
                background-color: #fff;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                background-color: #ae703e;
                color: #fff;
                height: 42px;
                border-right: 1px solid #fff;
              "
              slot="append"
              @click="search()"
              >站内搜索</el-button
            >

            <el-button
              style="
                background-color: #fff;
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
                background-color: #ae703e;
                color: #fff;
                height: 42px;
                margin-left: 15px;
              "
              slot="append"
              @click="juhesearch()"
              >图书检索</el-button
            >
          </el-input>
          <em class="line"></em>
        </div>
        <div class="right" v-if="tokenlogin==null">
          <p @click="$router.push({ path: '/accountlogin' })">登录</p>
          <p @click="$router.push({ path: '/register' })">注册</p>
        </div>
        <div class="right" v-else>
          <p @click="goCenter">个人中心</p>
          <p @click="backlogin">退出</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from "../api/api_person";
export default {
  name: "Header",
  data() {
    return {
      input3: "",
      select: "1",
      tokenlogin: "",
    };
  },
  methods: {
    //
    goCenter(){
      if(this.tokenlogin!==null){
      this.$router.push({
         path: '/Retrievalsetting',
        }); 
      }else{
        this.$message("请先登录");
    }
      },

    entersearch() {
      if (this.input3 == "") {
        this.$message({
          message: "请先输入要搜索的内容",
          type: "warning",
        });
      } else {
        this.$router.push({
          path: "/StationSearch",
          query: { type: this.select, title: this.input3 },
        });
      }
    },
    search() {
      if (this.input3 == "") {
        this.$message({
          message: "请先输入要搜索的内容",
          type: "warning",
        });
      } else {
        this.$router.push({
          path: "/StationSearch",
          query: { type: this.select, title: this.input3 },
        });
      }
    },
    juhesearch() {
      if (this.input3 == "") {
        this.$message({
          message: "请先输入要搜索的内容",
          type: "warning",
        });
      } else {
        this.$router.push({
          path: "/Aggregateretrieval",
          query: { type: this.select, title: this.input3 },
        });
      }
    },
    // @click="$router.push({ path: '/Aggregateretrieval' })"
    backlogin() {
      API.exit({}).then((res) => {
        if (res.code == 0) {
          this.$message("退出成功");
          setTimeout(() => {
            this.$router.push({ path: "/accountlogin" });
            localStorage.removeItem("tokenlogin")
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    enterSearchMember() {
      // this.search();
    },
  },
  created() {
    var lett = this;
    document.onkeydown = function (e) {
      var key = window.event.keyCode;
      if (key == 13) {
        lett.enterSearchMember();
      }
    };
    this.tokenlogin = localStorage.getItem("tokenlogin");
    
  },
};
</script>
<style scoped>
.hedertotal {
  width: 100%;
  height: auto;
  margin: 0 auto;
  background-color: #fff;
}

.header {
  width: 1200px;
  height: auto;
  margin: 0 auto;
  /* background-color: rgb(83, 70, 70); */
  display: flex;
  padding: 10px 0px;
  cursor: pointer;
}

.header .logo {
  height: 100%;
  display: inline-block;
  width: 50%;
  padding-top: 10px;
  /* font-size: 28px;
  line-height: 40px; */
  cursor: pointer;
}

.header .logo img {
  width: auto;
  height: auto;
  vertical-align: middle;
}

/* .header .logo p {
  font-family: Noto Serif SC;
  color: #e94f35;
  font-weight: 700;
} */
.header .login {
  width: 50%;
  position: relative;
}
.header .login .serchdiv {
  position: absolute;
  z-index: 999;
  right: 200px;
  top: -10px;
}
.header .login .serchdiv .line {
  position: absolute;
  right: 95px;
  top: 39px;
  width: 2px;
  height: 19px;
  background-color: #fff;
}
.header .el-input-group {
  width: 350px;
  margin: 27px 0px;
}

.header .right {
  height: 50px;
  margin-top: 22px;
  color: #000000;
  font-size: 14px;
  margin-left: 30px;
  text-align: right;
}

.header .right p {
  display: inline-block;
  width: auto;
  margin-right: 20px;
  cursor: pointer;
}
.el-input-group > .el-input__inner {
  border: none !important;
}
</style>
