<template>
  <div class="footer">
    <div class="cont">
      <div class="left">
        <p v-html="FooterLife.content"></p>
      </div>
      <div class="middle"  :style="'margin-left:'+margin+'px'">
        <p v-html="FooterRight.content"></p>
        <p>
          您是今日第<span class="visitors">{{ pvlist.intraday }}</span
          >位访问者
        </p>
        <p>
          网站总访问量:<span class="visitors">{{ pvlist.sum }}</span
          >位
        </p>
      </div>
      <div class="right">
        <div style="margin-right: 19px">
          <img src="../images/zzsr20.png" alt="" />
          <p style="color: #fff">微信公众号</p>
        </div>

        <div>
          <img src="../images/weixin.png" alt="" />
          <p style="color: #fff">今日头条公众号</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../api/api_user"
import APi from "../api/api_home"
export default {
  name: "Footer",
  props: {},
  data() {
    return {
      linkslist: "",
      pvlist: "",
      FooterLife: "",
      FooterRight: "",
      margin:''
    };
  },
  methods: {
    getsavePv() {
      API.savePv().then((res) => {
        this.pvlist = res;
      });
    },
    getFooterLife() {
      API.getFooterLife().then((res) => {
        this.FooterLife = res.data;
      });
    },
    getFooterRight(){
      API.getFooterRight().then((res) => {
        this.FooterRight = res.data;
      });
    },
    Footer() {
      APi.Footer().then((res) => {
        this.margin = res.data.footerBackGauge;
      });
    },
  },
  mounted() {
    this.getsavePv();
    this.getFooterLife();
    this.getFooterRight();
    this.Footer()
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.visitors {
  font-family: MicrosoftYaHei;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 2px;
  color: #ff4e00;
}
.footer {
  /* position: fixed; */
  bottom: 0px;
  width: 100%;
  height: auto;
  background-color: #bca78e;
  color: #ffffff;
  font-size: 12px;
  padding: 18px 0px;
  z-index: 999;
}
.footer .cont {
  width: 1200px;
  height: 280px;
  display: flex;
  margin-left: calc((100% - 1200px) / 2);
  position: relative;
}
/* .footer .cont div {
  flex: 1;
} */

.footer .cont div p {
  font-size: 14px;
  margin: 0px;
}
.footer .cont div h3 {
  font-size: 24px;
}
.footer .cont .right img {
  /* top: -55px;
  position: absolute; */
  width: 120px;
  height: 120px;
}
.right {
  display: flex;
  text-align: center;
  /* width: 200px; */
  position: absolute;
  /* left: 100px; */
  right: -55px;
  top: -58px;
}
</style>
