import * as API from '.'
export default {

    // 获取导航栏
    getMenu: params => {
        return API.GET('api/index/getMenu', params)
    },
    //根据分类获取文章列表
    getContentList: params => {
        return API.GET('api/index/getContentList', params)
    },
    //
    getClassify: params => {
        return API.GET('api/index/getClassify', params)
    },
}