import * as API from './'

export default {
    savePv: params => {
        return API.GET('api/index/getPV', params)
    },
    getFooterLife: params => {
        return API.GET('api/index/getFooterLife', params)
    },
    getFooterRight: params => {
        return API.GET('api/index/getFooterRight', params)
    },
    getList: params => {
        return API.GET('open/library/list', params)
    }
}