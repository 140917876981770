import * as API from './'

export default {
    //我的收藏
    intangibleChlist: params => {
        return API.POST('h5/collection/list', params)
    },
    //我的消息
    messagelists: params => {
        return API.POST('h5/user/message', params)
    },
    //删除全部
    delMessages: params => {
        return API.POST('h5/user/delMessages', params)
    },
    //删除单个
    delMessagesig: params => {
        return API.POST('h5/user/delMessage', params)
    },
    //我的点单
    getCulture: params => {
        return API.POST('h5/user/getCulture', params)
    },
    //我的活动
    activity: params => {
        return API.POST('h5/activity/activity', params)
    },
    //我的预约
    yuyue: params => {
        return API.POST('h5/user/getVenuesOrder', params)
    },
    //志愿者
    getVolunteer: params => {
        return API.POST('h5/user/getVolunteer', params)
    },
    //申请志愿者
    applyVolunteer: params => {
        return API.POST('h5/user/applyVolunteer', params)
    },
    //场馆列表
    venueslist: params => {
        return API.POST('h5/venues/list', params)
    },
    //上传头像
    saveAvatar: params => {
        return API.POST('h5/user/saveAvatar', params)
    },
    //获取基本信息
    getUser: params => {
        return API.POST('h5/user/getUser', params)
    },
    //修改基本信息
    updateUser: params => {
        return API.POST('h5/user/updateUser', params)
    },
    //实名认证
    authentication: params => {
        return API.POST('h5/user/authentication', params)
    },
    //获取实名认证信息
    getAuthentication: params => {
        return API.POST('h5/user/getAuthentication', params)
    },
    //修改密码
    backPWD: params => {
        return API.POST('api/user/backPWD', params)
    },
    //退出登录
    exit: params => {
        return API.POST('api/user/exit', params)
    },
    //手机短信
    RegSmsCode: params => {
        return API.POST('api/user/getUpdateSmsCode', params)
    },
    //添加个性点单
    saveVoteCulture: params => {
        return API.POST('h5/user/saveVoteCulture', params)
    },
    getVoteCulture: params => {
        return API.POST('h5/user/getVoteCulture', params)
    },
    //获取志愿者组织
    getOrganization: params => {
        return API.POST('h5/user/getOrganization', params)
    }
}