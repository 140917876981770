import * as API from './'
export default {
    // 新闻类型
    newsType: params => {
        return API.POST('api/news/type', params)
    },
    //阅读空间类型
    getresources: params => {
        return API.POST('api/resources/type', params)
    },
       //阅读空间列表
    getreadlist: params => {
        return API.POST('api/resources/list', params)
    },
    //首页轮播
    homebanner: params => {
        return API.GET('api/index/getShuffling', params)
    },
      //热榜
      gethot: params => {
        return API.GET('api/index/getHot', params)
    },
      //最新咨询
      getlatest: params => {
        return API.GET('api/index/getNews', params)
    },
    //最新资讯详情
    gethotDetails: params => {
        return API.GET('api/index/getDetails',params)
    },
    //新闻公告列表
    newsNoticeType : params => {
        return API.POST('api/news/list', params)
    },
    //艺术空间
    artSpaceType: params => {
        return API.GET('api/artSpace/type', params)
    },
    artSpaceDataList: params => {
        return API.POST('api/artSpace/list', params)
    },
    //科普世界类型
    scienceWorld:params => {
        return API.POST('api/popularizationOfScience/type', params)
    },
    //科普世界列表
    word:params=>{
        return API.POST('api/popularizationOfScience/list', params)
    },
    //馆校合作
    cooperationSchool:params => {
        return API.POST('api/cooperation/list', params)
    },
    cooperationSchooltype:params => {
        return API.POST('api/cooperation/type', params)
    },
    // 你好作家
    hellowriter:params => {
        return API.POST('api/writer/list', params)
    },
    hellowritertype:params => {
        return API.POST('api/writer/type', params)
    },
    // 家庭教育  api/eucation/list education
    familyEducation:params => {
        return API.POST('api/eucation/list', params)
    },
    familytype:params => {
        return API.POST('api/eucation/type', params)
    },
    //悬浮窗
    Door:params => {
        return API.GET('api/index/getFloatText', params)
    },
     //底部
     Footer:params => {
        return API.GET('api/index/getConfig', params)
    },
    //根据分类获取文章列表
    articleList:params => {
        return API.GET('api/index/getContentList', params)
    },
     //获取导航菜单
     getMenu:params => {
        return API.GET('api/index/getMenu', params)
    },
      //获取菜单分类
      getMenufity:params => {
        return API.GET('api/index/getClassify', params)
    },
      //获取专题悬浮窗开关
      getStatus:params => {
        return API.GET('api/hotTopic/status', params)
    },
      //专题分类
      getType:params => {
        return API.GET('api/hotTopic/getType', params)
    },
        //获取文章列表
        getTypeList:params => {
            return API.GET('api/hotTopic/list', params)
        },
        //获取子分类
        getMenuName:params => {
            return API.GET('api/index/getMenuName', params)
        },

}
