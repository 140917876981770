var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav"},[_c('el-menu',{staticClass:"el-menu-demo",attrs:{"background-color":"#ae703e","text-color":"#fff","active-text-color":"#fff","mode":"horizontal"}},[_c('el-menu-item',{attrs:{"index":"/"},on:{"click":function($event){return _vm.changePage1()}}},[_vm._v("首页")]),_vm._l((_vm.navmenu),function(i,k){return [(i.menuChildrenList.length != 0)?_c('el-submenu',{key:k,attrs:{"index":1 + '-' + k}},[_c('template',{slot:"title"},[_vm._v(_vm._s(i.menuName))]),_vm._l((i.menuChildrenList),function(item,index){return _c('el-menu-item',{key:index,attrs:{"index":1 + '-' + k + '-' + index},on:{"click":function($event){return _vm.changePage(
              '/list-text',
              item.id,
              item.menuParentId,
              i.menuName,
              item.name,
              item.menuType
            )}}},[_vm._v(_vm._s(item.name))])})],2):_c('el-menu-item',{key:k,attrs:{"index":1 + '-' + k},on:{"click":function($event){return _vm.checkpage('/list-text', i.id, i.menuId, i.menuName, i.name)}}},[_vm._v(_vm._s(i.menuName))])]}),( this.navmenu.length>8)?_c('el-submenu',{attrs:{"index":"2"}},[_c('template',{slot:"title"},[_vm._v("更多")]),_vm._l((_vm.more),function(item,k1){return _c('el-submenu',{key:k1,attrs:{"index":2 + '-' + k1}},[_c('template',{slot:"title"},[_vm._v(_vm._s(item.menuName))]),_vm._l((item.menuChildrenList),function(i,k2){return _c('el-menu-item',{key:k2,attrs:{"index":2 + '-' + k2},on:{"click":function($event){return _vm.changePage(
              '/list-text',
              i.id,
              i.menuParentId,
              item.menuName,
              i.name,
              i.menuType
            )}}},[_vm._v(_vm._s(i.name))])})],2)})],2):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }